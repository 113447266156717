import axios from "axios";
const clientId = process.env.NEXT_PUBLIC_STOREFRONT_CLIENT_ID;

const loadRefreshToken = async () => {
  const body = new URLSearchParams();
  const { refresh_token } = JSON.parse(localStorage.getItem("authTokens"));
  body.append("grant_type", "refresh_token");
  body.append("client_id", clientId);
  body.append("refresh_token", String(refresh_token));
  const headers = {
    "content-type": "application/x-www-form-urlencoded",
  };
  const response = await fetch(
    `https://shopify.com/10206689/auth/oauth/token`,
    {
      method: "POST",
      headers: headers,
      body,
    }
  );
  const data = await response.json();
  const dateExpired = new Date();
  dateExpired.setHours(dateExpired.getHours() + data.expires_in / 60 / 60);
  if (data.access_token) {
    localStorage.setItem(
      "authTokens",
      JSON.stringify({ ...data, dateExpired })
    );
  }
};

export const tokenInterceptor = async (
  url,
  method = "GET",
  body = null,
  id = ""
) => {
  let token = await (localStorage.getItem("authTokens")
    ? JSON.parse(localStorage.getItem("authTokens"))
    : null);
  const isExpired = new Date() > new Date(token.dateExpired);
  if (isExpired) {
    await loadRefreshToken();
    token = await JSON.parse(localStorage.getItem("authTokens"));
  }
  if (token) {
    if (method === "POST")
      return await axios.post(
        `${url}?token=${token.access_token}&id=${id}`,
        body
      );
    return await axios.get(`${url}?token=${token.access_token}&id=${id}`);
  }
};
