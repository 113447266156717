import qs from "querystring";
import {
  FC,
  useEffect,
  useMemo,
  createContext,
  useContext,
  useState,
} from "react";
import { createClient } from "@supabase/supabase-js";
import axios from "axios";
import sha256 from "crypto-js/sha256";
import { includes, isNumber } from "lodash";
import { useRouter } from "next/router";
import { useQuery, useQueryClient } from "react-query";

import { useShopify } from "~context";
import { formatShopifyIdForTracking, showToast } from "~lib";
import { getShopifySdk } from "~lib/shopify/client";

const shopifyIdBase = "gid://shopify/Product/";

const LOCAL_STORAGE_WISHLIST = "wishlist";
const LOCAL_STORAGE_RECIPES_WISHLIST = "recipes";

export const transformId = (id, type = "numeric") => {
  if (!id) return 0;

  return type === "numeric"
    ? isNumber(id)
      ? id
      : id.replace(shopifyIdBase, "")
    : btoa(`${shopifyIdBase}${id}`);
};

export interface WishlistContextType {
  wishlist: any;
  loadingWishlist: boolean;
  addOrRemoveFromWishlist: (action: string, data: any) => void;
  wishlistCount: number;
  recipesWishlist: string[];
  loadingRecipesWishlist: boolean;
  addOrRemoveFromRecipesWishlist: (action: string, id: string) => void;
}

export const WishlistContext = createContext<WishlistContextType>(undefined);
export const WishlistProvider: FC = ({ children }) => {
  const [wishlistCount, setWishlistCount] = useState(0);
  const router = useRouter();
  const queryClient = useQueryClient();

  const { customer } = useShopify();

  const shopifySdk = useMemo(
    () => getShopifySdk(router?.locale),
    [router?.locale]
  );
  const supabase = createClient(
    process.env.NEXT_PUBLIC_SUPABASE_URL,
    process.env.NEXT_PUBLIC_SUPABASE_KEY
  );
  const getShopifyProducts = async (items) => {
    if (items?.length) {
      const ids = items
        ?.filter((item) => !item.images && item.product_id)
        .map((item) =>
          isNumber(Number(item?.product_id))
            ? transformId(item.product_id, "string")
            : item.product_id
        );

      if (ids?.length) {
        try {
          const shopifyProducts = (await shopifySdk.productsByIds({ ids }))
            ?.nodes;

          return items?.map((item) => ({
            ...item,
            ...shopifyProducts?.find(
              (prod) =>
                // @ts-ignore
                item?.handle === prod?.handle || item?.product_id === prod?.id
            ),
          }));
        } catch (err) {
          console.log(err);
        }
      } else return items;
    } else return [];
  };
  const getWishlist = async () => {
    try {
      if (customer?.id) {
        const { data } = await axios(
          `https://advanced-wishlist.hulkapps.com/api/public/v1/wishlist/products?per_page=50&customer_id=${formatShopifyIdForTracking(
            customer?.id
          )}`,
          {
            headers: {
              Authorization: `Bearer ${process.env.NEXT_PUBLIC_WISHLIST_TOKEN}`,
              "Encrypt-token": btoa(
                sha256(
                  "natural-heroes-nl.myshopify.com" +
                    formatShopifyIdForTracking(customer?.id)
                ).toString()
              ),
            },
          }
        );
        setWishlistCount(data.total);
        const items = await getShopifyProducts(data.data);
        return items;
      } else {
        const items = localStorage.getItem(LOCAL_STORAGE_WISHLIST);

        const data = await getShopifyProducts(
          items !== "undefined" ? JSON.parse(items) : []
        );
        setWishlistCount(data.length);
        return data;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const {
    data: wishlist,
    isLoading: loadingWishlist,
    refetch: refetchWishlist,
  } = useQuery(["getWishlist"], getWishlist);

  const addOrRemoveFromWishlist = async (
    action,
    { productId, variantId, product = {} }
  ) => {
    const numericProductId = isNaN(Number(productId))
      ? formatShopifyIdForTracking(productId)
      : Number(productId);
    const numericVariantId = isNaN(Number(variantId))
      ? formatShopifyIdForTracking(variantId)
      : Number(variantId);
    if (customer?.id) {
      try {
        if (action === "add") {
          await axios.post(
            `https://advanced-wishlist.hulkapps.com/api/public/v1/wishlist/add_product?product_id=${numericProductId}&customer_id=${formatShopifyIdForTracking(
              customer?.id
            )}&variant_id=${numericVariantId}`,
            null,
            {
              headers: {
                Authorization: `Bearer ${process.env.NEXT_PUBLIC_WISHLIST_TOKEN}`,
                "Encrypt-token": btoa(
                  sha256(
                    "natural-heroes-nl.myshopify.com" +
                      formatShopifyIdForTracking(customer?.id)
                  ).toString()
                ),
              },
            }
          );
        }

        if (action === "remove") {
          await axios.post(
            `https://advanced-wishlist.hulkapps.com/api/public/v1/wishlist/delete_product?product_id=${numericProductId}&customer_id=${formatShopifyIdForTracking(
              customer?.id
            )}&variant_id=${numericVariantId}`,
            null,
            {
              headers: {
                Authorization: `Bearer ${process.env.NEXT_PUBLIC_WISHLIST_TOKEN}`,
                "Encrypt-token": btoa(
                  sha256(
                    "natural-heroes-nl.myshopify.com" +
                      formatShopifyIdForTracking(customer?.id)
                  ).toString()
                ),
              },
            }
          );
        }
        refetchWishlist();
      } catch (err) {
        console.log(err);
      }
    } else {
      const updatedWishlist =
        action === "add"
          ? [...(wishlist || []), { product_id: productId, ...product }]
          : wishlist?.filter((item) => item.id !== productId);
      localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));
      refetchWishlist();
    }

    queryClient.refetchQueries("wishlist");
  };

  const getRecipesWishlist = async () => {
    try {
      if (customer?.id) {
        let { data: recipes_wishlist, error } = await supabase
          .from("recipes_wishlist")
          .select("recipe_id")
          .eq("email", customer.emailAddress.emailAddress);
        if (error) return [];
        return recipes_wishlist.map((item) => item.recipe_id);
      } else {
        const items = localStorage.getItem(LOCAL_STORAGE_RECIPES_WISHLIST);
        const data = await getShopifyProducts(
          items !== "undefined" ? JSON.parse(items) : []
        );
        return data;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const {
    data: recipesWishlist,
    isLoading: loadingRecipesWishlist,
    refetch: refetchRecipesWishlist,
  } = useQuery(["getRecipesWishlist", customer], getRecipesWishlist);
  const addOrRemoveFromRecipesWishlist = async (action, id) => {
    if (customer?.id) {
      try {
        if (action === "add") {
          const { error } = await supabase
            .from("recipes_wishlist")
            .insert([
              { email: customer.emailAddress.emailAddress, recipe_id: id },
            ]);
          if (error) showToast("Error", "error");
        }
        if (action === "remove") {
          const { error } = await supabase
            .from("recipes_wishlist")
            .delete()
            .eq("email", customer.emailAddress.emailAddress)
            .eq("recipe_id", id);
          if (error) showToast("Error", "error");
        }
        refetchRecipesWishlist();
      } catch (err) {
        console.log(err);
      }
    } else {
      const updatedWishlist =
        action === "add"
          ? [...(recipesWishlist || []), id]
          : recipesWishlist?.filter((item) => item !== id);
      localStorage.setItem(
        LOCAL_STORAGE_RECIPES_WISHLIST,
        JSON.stringify(updatedWishlist)
      );
      refetchRecipesWishlist();
    }
  };
  const updateRecipesOnLogin = async (ids) => {
    try {
      const { data, error } = await supabase
        .from("recipes_wishlist")
        .select("recipe_id")
        .eq("email", customer.emailAddress.emailAddress);
      if (error) throw new Error();
      const oldIds = data.map((item) => item.recipe_id);
      if (oldIds.length) {
        const idsToInsert = ids.filter((item) => !oldIds.includes(item));
        const { error } = await supabase.from("recipes_wishlist").insert(
          idsToInsert.map((recipe_id) => ({
            email: customer.emailAddress.emailAddress,
            recipe_id,
          }))
        );
        if (error) showToast("Error", "error");
        else
          localStorage.setItem(
            LOCAL_STORAGE_RECIPES_WISHLIST,
            JSON.stringify([])
          );
      }
    } catch {}
  };
  useEffect(() => {
    if (customer?.id) {
      const wishlistItem = localStorage.getItem(LOCAL_STORAGE_WISHLIST);
      const tempWishlist =
        wishlistItem === "undefined" || !wishlistItem || wishlistItem === "null"
          ? []
          : JSON.parse(wishlistItem);
      const ids = tempWishlist.map((item) => {
        return {
          productId: item.id,
          variantId: item?.variants?.edges[0]?.node?.id || item?.objectID,
        };
      });
      if (ids?.length) {
        ids?.forEach(({ productId, variantId }) =>
          addOrRemoveFromWishlist("add", {
            productId,
            variantId,
          })
        );
        localStorage.setItem(LOCAL_STORAGE_WISHLIST, JSON.stringify([]));
      }
      refetchWishlist();
      const recipeWishlistItems = localStorage.getItem(
        LOCAL_STORAGE_RECIPES_WISHLIST
      );
      const tempRecipesWishlist =
        recipeWishlistItems === "undefined" ||
        !recipeWishlistItems ||
        recipeWishlistItems === "null"
          ? []
          : JSON.parse(recipeWishlistItems);
      if (tempRecipesWishlist.length) {
        updateRecipesOnLogin(tempRecipesWishlist);
      }
    }
  }, [customer?.id]);

  return (
    <WishlistContext.Provider
      value={{
        // Wishlist
        wishlist,
        wishlistCount,
        loadingWishlist,
        addOrRemoveFromWishlist,
        recipesWishlist,
        loadingRecipesWishlist,
        addOrRemoveFromRecipesWishlist,
      }}
    >
      {children}
    </WishlistContext.Provider>
  );
};

export const useWishlist = () => {
  const ctx = useContext(WishlistContext);

  if (!ctx) {
    throw new Error(
      "Wishlist context must be used within a wishlist context provider"
    );
  }

  return ctx;
};
